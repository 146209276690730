import React, { useCallback, useState } from 'react';
import { AspectRatio, Box, Skeleton } from '@chakra-ui/react';
import { imageURL } from '@theark/react-common';
import fallBackLogo from '../../assets/images/logo.png';
import ImageWithFallback from '../ImageWithFallback';
import { uniqueId } from 'lodash';

export const RenderNftAsset: React.FC<any> = ({
	animation_url,
	image_url,
	isImageFullHeight,
	imageAlt,
	onClick,
	fullAsset,
	sx,
	key,
	quality = 60,
}) => {
	const fallbackCover: string = image_url ? imageURL(image_url) : '';
	const fallbackAnimation: string = animation_url ? imageURL(animation_url) : '';
	const [isContextMenuDisabled, setIsContextMenuDisabled] = useState(false);

	const handleContextMenu = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		setIsContextMenuDisabled(true);
		setTimeout(() => setIsContextMenuDisabled(false), 1000);
	}, []);

	const handleImageDragStart = useCallback((e: React.DragEvent) => {
		e.preventDefault();
	}, []);

	return (
		<Skeleton isLoaded={animation_url || image_url}>
			{animation_url ? (
				<AspectRatio maxW={fullAsset ? '100%' : '390px'} ratio={1} sx={sx}>
					<video
						autoPlay
						loop
						muted={fullAsset ? false : true}
						preload="auto"
						playsInline
						webkit-playsinline="true"
						x5-playsinline="true"
						controls={fullAsset ? true : false}
						controlsList="nodownload"
						className={fullAsset ? '' : !isImageFullHeight ? 'nftVideo' : ''}
						src={fallbackAnimation}
						onClick={onClick}
					/>
				</AspectRatio>
			) : (
				<Box
					position="relative"
					onContextMenu={handleContextMenu}
					style={{
						WebkitTouchCallout: 'none',
						WebkitUserSelect: 'none',
						cursor: isContextMenuDisabled ? 'not-allowed' : 'pointer',
					}}
				>
					<ImageWithFallback
						key={key ?? uniqueId()}
						src={fallbackCover}
						fallbackSrc={fallBackLogo.src}
						blurDataURL={fallBackLogo.blurDataURL}
						placeholder="blur"
						alt={imageAlt ?? 'NFT Image'}
						height={isImageFullHeight ? 300 : 268}
						width={fullAsset ? 1080 : 390}
						loading="lazy"
						onError={(e) => {
							(e.target as HTMLImageElement).src = fallBackLogo.src;
						}}
						onClick={onClick}
						onDragStart={handleImageDragStart}
						style={{
							height: isImageFullHeight ? '100%' : 300,
							width: '100%',
							objectFit: 'cover',
							pointerEvents: isContextMenuDisabled ? 'none' : 'auto',
							...(sx && sx),
						}}
						quality={quality}
					/>
					{isContextMenuDisabled && (
						<Box
							position="absolute"
							top="50%"
							left="50%"
							transform="translate(-50%, -50%)"
							bg="rgba(0,0,0,0.7)"
							color="white"
							padding="2"
							borderRadius="md"
						>
							Saving is disabled
						</Box>
					)}
				</Box>
			)}
		</Skeleton>
	);
};
